@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

*, ::after, ::before {
  box-sizing: border-box;
}

/* * {
  border: 1px solid red;
} */

:root {
  --primary: #d33409;
  --primary-hover: #fd5631;
  --accent: #fd5631;
  --secondary: #000;
  --green: #16c784;
  --red: #dc1616;
  --orange: #ff913d;
  --quatar: #6f42c1;
  --grey: #efefef;
  --tblr-border-radius: 13px;
  --color-text-xsmall: #999;
  --color-ra-ws-grey: #aaa;
  --color-ra-ws-green: #16c784;
  --margin-standard: 1rem;
  --font-size-xsmall: 13px;
  --font-size-icon: 20px;
  --font-size-span: 8px;
}

body {
  margin: 0;
  font-family: 'system-ui', '-apple-system', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  overflow-x: hidden !important; 
  box-sizing: border-box !important;
  background: rgb(247, 248, 250) !important;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
margin-top: 0;
margin-bottom: .5rem;
font-weight: 500;
line-height: 1.2;
}

h4 {
  font-size: 1.5rem;
}


/* SideBar */

main {
  height: 100vh;
  height: -webkit-fill-available;
  max-height: 100vh;
  overflow-x: auto;
  overflow-y: hidden;
}

.dropdown-toggle { outline: 0; }

.btn-toggle {
  padding: .25rem .5rem;
  font-weight: 600;
  color: var(--bs-emphasis-color);
  background-color: transparent;
}
.btn-toggle:hover,
.btn-toggle:focus {
  color: rgba(var(--bs-emphasis-color-rgb), .85);
  background-color: var(--bs-tertiary-bg);
}

.btn-toggle::before {
  width: 1.25em;
  line-height: 0;
  content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%280,0,0,.5%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
  transition: transform .35s ease;
  transform-origin: .5em 50%;
}

[data-bs-theme="dark"] .btn-toggle::before {
  content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%28255,255,255,.5%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
}

.btn-toggle[aria-expanded="true"] {
  color: rgba(var(--bs-emphasis-color-rgb), .85);
}
.btn-toggle[aria-expanded="true"]::before {
  transform: rotate(90deg);
}

.btn-toggle-nav a {
  padding: .1875rem .5rem;
  margin-top: .125rem;
  margin-left: 1.25rem;
}
.btn-toggle-nav a:hover,
.btn-toggle-nav a:focus {
  background-color: var(--bs-tertiary-bg);
}

.scrollarea {
  overflow-y: auto;
}

/* SideBar */

.mainlogoImage {
  width: 45px;
  border: solid 1px #efefef;
  padding: 4px;
  border-radius: 0.5rem;
}

.bi-lightning-charge, .bi-tropical-storm, .bi-gear-wide-connected {
  color: var(--primary); /* Use the primary color variable */
  font-size: 22px;       /* Set the font size to 22px */
}

.dashboard-logo {

   line-height: 30px;
    width: 30px;
    text-align: center;
    background: linear-gradient(135deg, rgb(255, 106, 0), rgb(255, 72, 0) 50%, rgb(192, 51, 0));
    font-size: 12px;
    font-weight: 400;
    color: #fff;
    border-radius: 100px;
    display: inline-block;

  } 

  .ra-home {
    border: solid 1px var(--grey);
    border-radius: 0.5rem;
    padding: 1.5rem;
}

.ra-credits {
  border: solid 1px var(--grey);
  border-radius: 0.5rem;
  padding: 0.5rem 1.2rem;
  
  /* height: 100%; */
}

.text-primarymain {
  color: var(--primary)!important;
}

.h2, h2 {
  font-size: 2.75rem;
}

.text-small {
  font-size: 14px;
}

code {
  font-size: .875em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}

/* .active, .active > * {
  color: #fff !important;
  background-color: var(--primary)!important;
} */


.ra-home {
  border: solid 1px var(--grey);
  border-radius: 0.5rem;
  padding: 1.5rem;
}

.keywordResearchmainparagraph {
  margin-bottom: 1.5rem;
}

.findkws {
  background-color: #212529 !important;
}

.my-tags .btn.disabled {
  background-color: #999 !important;
}

.my-tags .btn {
  font-size: 12px;
  color: #fff !important;
}
.my-tags {
  min-height: 32px;
}

.ra-table {
  border: solid 1px var(--grey);
  border-radius: 0.5rem;
}
.ra-tablemain {
  border: solid 1px var(--grey);
  /* border-radius: 0.5rem; */
}

th {
  font-weight: 400;
  font-size: 14px;
  line-height: 32px;
  text-transform: uppercase;
}

.ra-table th, .ra-table td {
  border-bottom: none;
  border-top: solid 1px var(--grey);
}


/* .custom-checkbox .form-check-input:checked {
  background-color: #d33409 !important;
  border-color: #d33409 !important;
  color: #fff !important;
} */

.ra-flag > img{
  height: 20px;
  width: 30px;
  border-radius: 0.30rem;
  /* margin-left: 5px; */
  margin: 2px 12px;
}

td {
  font-size: 14px;
}

.registerBUtton {
  border: 1px solid #d33409 !important;
}

.special-button-wrapper {
  position: relative;
  display: inline-block;
}
.special-effect {
  position: absolute;
  top: calc(50% + 5px);; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Adjust the position to truly center it */
  
  width: 90%; /* Width of the effect */
  height: 75%; /* Height of the effect */
  background-image: linear-gradient(165deg, oklch(0.673 0.266 25.039656), oklch(0.8582 0.201 91.19));
  filter: blur(20px);
  z-index: -1; /* Place it behind the content */
  }
  
  .account-block {
    padding: 0;
    background-image: url(https://bootdey.com/img/Content/bg1.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    position: relative;
    border-radius: 1rem;
}

.account-block .overlay {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 1rem;
  background-color: rgba(0, 0, 0, 0.4);
}

.account-block .account-testimonial {
  text-align: center;
  color: #fff;
  position: absolute;
  margin: 0 auto;
  padding: 0 1.75rem;
  bottom: 3rem;
  left: 0;
  right: 0;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}





.p-5 {
  padding: 3rem!important;
}

.h5, h5 {
  font-size: 1.25rem;
}

.text-muted {
 color: rgba(33, 37, 41, 0.5);
}


.customButtonStyle, .customButtonStyle:hover {
  color: #fff;
  background-color: #d33409;
  border-color: #d33409;
  text-decoration: none;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15); /* Normal shadow */
  outline: none; /* To remove default focus outline */
  width: 200px;
}


.text-primarymain {
  color: var(--primary);
}

.loginbutton, .loginbutton:hover {
  width: 100px;
}

.bg-success {
  background-color: #198754;
}


.merge-now {
  position: sticky;
  z-index: 999;
  bottom: 1rem;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
}

.merge-now div {
  display: inline-block;
  background-color: #26264e;
  color: #fff;
  font-size: 13px;
  padding: 8px 24px;
  padding-right: 8px;
  border-radius: 0.35rem;
}

.mergemain {
  /* position: sticky !important; */
  position: fixed !important;
  z-index: 999;
  bottom: 1rem;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;

}

.mergemain div {
  display: inline-block;
  background-color: #26264e;
  color: #fff;
  font-size: 13px;
  padding: 8px 24px;
  padding-right: 8px;
  border-radius: 0.35rem;
}

.custom-width-button {
  width: 90px !important;
  border: none !important;
}

.keyword-link {
  /* display: block; */
  /* width: 100%; */
  text-decoration: none;
  color: #212529;
  font-weight: 700;
}

.text-xsmall {
  font-size: 13px;
  color: #999;
}

.h3, h3 {
  font-size: 1.75rem;
}

.ra-name {
  text-transform: capitalize;
}

/* .customkeyword > .tooltip-arrow .arrow:after {
  
  background-color: #efefef !important;
}

.customkeyword .tooltip-inner {
  background-color: #efefef !important; 
  color: #000;
} */

/* Overriding the tooltip background color */
.customkeyword .tooltip-inner {
  background-color: #efefef !important; /* Your desired background color */
  color: #000 !important; /* Your desired text color */
}

/* .customkeyword .tooltip-arrow,
.customkeyword .tooltip-arrow::before,
.customkeyword .tooltip-arrow::after {
  border-bottom-color: #efefef !important; /* Change the color as needed 
} 
*/

.customkeyword .tooltip-inner {
  background-color: #efefef !important; /* Your desired background color */
  color: #000 !important; /* Your desired text color */
}

.custom-tooltip-text-color .ant-tooltip-inner {
  color: #000 !important; /* Set your desired text color */
}

.custom-tooltip-wide .ant-tooltip-inner {
  color: #000 !important; /* Set your desired text color */
  width: 500px !important;
}

/* Tooltip on the top */
/* .customkeyword.bs-tooltip-top .tooltip-arrow,
.customkeyword.bs-tooltip-top .tooltip-arrow::before,
.customkeyword.bs-tooltip-top .tooltip-arrow::after {
  border-top-color: #efefef !important; 
}


.customkeyword.bs-tooltip-right .tooltip-arrow,
.customkeyword.bs-tooltip-right .tooltip-arrow::before,
.customkeyword.bs-tooltip-right .tooltip-arrow::after {
  border-right-color: #efefef !important; 
}


.customkeyword.bs-tooltip-bottom .tooltip-arrow,
.customkeyword.bs-tooltip-bottom .tooltip-arrow::before,
.customkeyword.bs-tooltip-bottom .tooltip-arrow::after {
  border-bottom-color: #efefef !important; 
}


.customkeyword.bs-tooltip-left .tooltip-arrow,
.customkeyword.bs-tooltip-left .tooltip-arrow::before,
.customkeyword.bs-tooltip-left .tooltip-arrow::after {
  border-left-color: #efefef !important;  
} 

*/


/* Increase specificity to override Bootstrap's default tooltip arrow styles */
.bs-tooltip-auto[data-popper-placement^=left] .customkeyword .tooltip-arrow::before, 
.bs-tooltip-start.customkeyword .tooltip-arrow::before {
    border-left-color: #efefef !important; /* Your desired color */
}

/* Repeat for each placement as necessary */
.bs-tooltip-auto[data-popper-placement^=right] .customkeyword .tooltip-arrow::before, 
.bs-tooltip-end.customkeyword .tooltip-arrow::before {
    border-right-color: #efefef !important; 
}

.bs-tooltip-auto[data-popper-placement^=top] .customkeyword .tooltip-arrow::before, 
.bs-tooltip-top.customkeyword .tooltip-arrow::before {
    border-top-color: #efefef !important;
}

.bs-tooltip-auto[data-popper-placement^=bottom] .customkeyword .tooltip-arrow::before, 
.bs-tooltip-bottom.customkeyword .tooltip-arrow::before {
    border-bottom-color: #efefef !important;
}


.ra-number {
  background-color: #efefef;
  font-size: 11px;
  padding: 2px 8px;
  /* vertical-align: top; */
  font-weight: 400;
  border-radius: 20px;
  transition: all 0.3s ease-in-out;
}

.ms-1 {
  margin-left: 0.25rem!important;
}

.text-small {
  font-size: 14px;
}

.sticky {
  position: sticky !important;
  top: 1px;
}

/* Legends */
.ra-credits p {
  cursor: pointer;
  margin-top: 0;
}

.text-xsmall {
  color: var(--color-text-xsmall);
  font-size: var(--font-size-xsmall);
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.me-3 {
  margin-right: var(--margin-standard) !important;
}

.leg {
  display: inline-block;
  margin-top: 6px;
  vertical-align: top;
}

.ra-ws {
  position: relative;
}

.ra-ws span {
  background: var(--color-ra-ws-grey); /* Default background for all */
  border-radius: 25px;
  color: #fff;
  font-size: var(--font-size-span);
  line-height: 12px;
  position: absolute;
  right: -4.5px;
  text-align: center;
  top: -8.5px;
  width: 12px;
}

.ra-ws .bi {
  font-size: var(--font-size-icon);
  transition: all 0.6s ease-in-out;
}

.ra-ws.grey {
  color: var(--color-ra-ws-grey);
}

.green {
  color: var(--color-ra-ws-green);
}

.green span {
  background-color: var(--color-ra-ws-green);
}

.green i {
  color: var(--color-ra-ws-green);
}

.ra-ws.primary {
  color: var(--primary);
}

.ra-ws.primary span {
  background-color: var(--primary);
}

.ra-ws.yellow {
  color: var(--quatar);
}

.ra-ws.yellow span {
  background-color: #6f42c1;
}

.ra-ws.blue {
  color: #0065fc;
}

.ra-ws.blue span {
  background-color: #0065fc;
}

.ra-table {
  border: solid 1px var(--grey);
    border-radius: 0.5rem
}

.ra-table .form-control {
  border: none;
  line-height: 32px;
}

.bi-quora {
  color: #b92b27 !important;
}



.quoraBackground {
  background-color: #b92b27 !important;
}

.bi-reddit {
  color: #FF5700 !important;
}

.redditBackground {
  background-color: #FF5700 !important;
}
.bi-youtube {
  color: #FF0000 !important;
}

.youtubeBackground {
  background-color: #FF0000 !important;
}
.bi-facebook {
  color: #4267B2 !important;
}

.facebookBackground {
  background-color: #4267B2 !important;
}
.bi-twitter {
  color: #1DA1F2 !important;
}

.twitterBackground {
  background-color: #1DA1F2 !important;
}
.bi-linkedin {
  color: #2867B2 !important;
}

.linkedinBackground {
  background-color: #2867B2 !important;
}

.ra-wss span {
  /* top: -1.5px; */
  top: -1.5px;
  right: -2px;
}


.gXufQA {
  border-right: 0.5px solid #b9b4b4 !important;
}


.analyse-now div .btn-white {
  color: #000 !important;
  background-color: #fff;
  font-size: 12px;
  line-height: 12px;
  border-radius: 0.25rem;
}

/* .rdt_TableBody .rdt_TableRow .rdt_TableCell input[type='checkbox'] {
  accent-color: var(--primary) !important;
} */


.white-spinner  {
  color: #fff !important; /* or any other color that matches the btn-white style */
  top: 30%; /* Moves the top edge to the middle of the container */
  margin-left: 20px;
 position: absolute;
  
  z-index: 2;
}



.hgBzTD {
  width: 22px !important;
}

/* .activeTab {
  color: var(--primary);
  font-weight: 500;
}

.ra-subnav p.activeTab .ra-number {
  background-color: var(--primary);
  color: #fff;
} */

.activeTab {
  color: rgb(7, 20, 39);
  /* background: rgb(247, 248, 250); */
  font-weight: 500;
}

.ra-subnav p {
  line-height: 34px;
}

.ra-subnav p.activeTab .ra-number {
  background-color: rgb(7, 20, 39);
  color: #fff;
  
}

/* .sc-hKgKIp.sc-bqyKbq.bkAuMe.fYhbaE.rdt_TableCol input[type='checkbox'] {
  accent-color: var(--primary) !important;
} */

.h5, h5 {
  font-size: 1.25rem;
}

.cpcs {
  border: solid 1px var(--primary);
  border-radius: 0.5rem;
  line-height: 3rem;
  color: var(--primary);
}

.text-xxsmall {
  font-size: 10px;
  color: #666;
}

/* Custom CSS file or style tag */
.offcanvas-custom-size {
  max-height: 100% !important; /* Adjust the width as needed */
  height: 73% !important; /* Set a specific width */
}
.offcanvas-custom-sizemain {
  max-height: 100% !important; /* Adjust the width as needed */
  max-width: 100% !important; /* Adjust the width as needed */
  height: 100% !important; /* Set a specific width */
  width: 50% !important; 
}
.offcanvas-custom-Clusters {
  max-height: 100% !important; /* Adjust the width as needed */
  max-width: 100% !important; /* Adjust the width as needed */
  height: 76% !important; /* Set a specific width */
  width: 100% !important; 
}


.ra-wsss span {
  top: 0.1px;
  right: 1px;
}

.ra-wsssupdate span {
  top: -8px;
  right: -3px;
}


.cpcs .col-md-5 {
  font-size: 32px;
  background-color: #d3340911;
}

.spinnerparent {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.longUrl {
  white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    max-width: 100px !important;
}


/* Filters */

.ra-filter .btn, .ra-filter select {
  font-size: 13px;
  color: #999;
  border-radius: 0.25rem;
  padding: 2px 10px !important;
  border: solid 1px #bbb;
}

.ra-filter select:hover {
  /* background-color: var(--primary); .ra-filter .btn:hover, */ 
  color: var(--primary); 
  background-color: #d3340922;
  border-color: var(--primary);
}

.topicbutton:hover {
  color: var(--primary) !important; 
  background-color: #d3340922 !important;
  border-color: var(--primary) !important;
}

.topic {
  font-size: 13px !important;
  color: #999 !important;
  background-color: #fff !important;
  border-radius: 0.25rem !important;
  padding: 2px 10px !important;
  border: solid 1px #bbb !important;
}

.topicclip {
  font-size: 14px !important;
  color: #999 !important;
  background-color: #fff !important;
  border-radius: 0.25rem !important;
  padding: 2px 10px !important;
  border: solid 1px #bbb !important;
  margin: 12px 5px 0 0;
}

.topicbutton {
  font-size: 14px !important;
  color: #999 !important;
  background-color: #fff !important;
  border-radius: 0.25rem !important;
  padding: 2px 10px !important;
  border: solid 1px #bbb !important;
  /* margin: 12px 5px 0 0; */
}



.topicclipTop {
  margin: 2px 0 0 0;
}

.ra-filter .btn-dark {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.ra-table {
  border: solid 1px var(--grey) !important;
  border-radius: 0.5rem;
}

/* .btn-check:checked+.btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active {
  color: var(--primary) !important; 
  background-color: #d3340922 !important;
  border-color: var(--primary) !important;
} */

.dropdown-menu {
  --bs-dropdown-link-active-bg: var(--primary) !important; 
  --bs-dropdown-min-width: 14rem !important;
}

.mainFilter {
  border-left: solid 1px var(--grey) !important;
  border-right: solid 1px var(--grey) !important;
  border-top: none !important;
  border-bottom: none !important;
}

.btn-outline-primary {
  --bs-btn-color: var(--primary) !important;
  --bs-btn-border-color: var(--primary)!important;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: var(--primary)!important;
  --bs-btn-hover-border-color: var(--primary)!important;
  --bs-btn-focus-shadow-rgb: 13,110,253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: var(--primary)!important;
  --bs-btn-active-border-color: var(--primary)!important;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125)!important;
  --bs-btn-disabled-color: var(--primary)!important;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: var(--primary)!important;
  --bs-gradient: none;
}


.ra-filter .value-present {
  color: var(--primary) !important; 
  background-color: #d3340922 !important;
  border-color: var(--primary) !important;
}



.ra-filter .topicclip:hover { 
  background-color: #d3340922 !important; 
  border-color: var(--primary) !important; 
  color: var(--primary) !important; 
}

.bg-success-temporary {
  background-color: #198754 !important; /* Bootstrap's success color */
  color: #fff !important;
  border-color: #fff !important; 
}
.bg-success-temporary:hover {
  background-color: #198754 !important; /* Bootstrap's success color */
  color: #fff !important;
  border-color: #fff !important; 
}


.bi-star.active {
  color: var(--primary);
}

.hgBzTD, .fYhbaE {
  flex: 0 0 30px !important;
  min-width: 15px !important;
}

.startdiv {
  margin: 0 !important;
  padding: 0 !important;
  cursor: pointer;
}

.bi-star-fill {
  color: var(--primary);
}


/* Clusters */
.keyword-count {
  margin: 0 5px;
  padding: 3px 5px;
  border-radius: 5px;
 background-color: var(--primary);
 color: #fff;
}
.keyword-count-item {
 
  padding-top: 0.1rem !important; 
  padding-bottom: 0.1rem !important;
}

.bi-arrow-repeat {cursor: pointer;}


.mycustomClass1 {
  color: var(--quatar) !important;
}
.mycustomClass2 {
  background-color: var(--quatar) !important;
}
.mydomainmyClass1 {
  color: #0065fc !important;
}
.mydomainmyClass2 {
  background-color: #0065fc !important;
}


.collapse-expand-icon {
  position: absolute;
  left: 100; 
  top: 5; 
  transform: translateX(-100%); 
  z-index: 1000; 
  background-color: #f2f2f4;
  padding: 5px;
  border-radius: 0.5rem;
}

.ps-menu-button {
  height: 40px !important;
  font-family: "Inter", "San Francisco", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
  padding: 0 10px !important;
  color: #565B67 !important;
}

.ps-menu-button:hover {
  color: #0065fc !important;
  /* color: #330582 !important; */
}

.text-primarymainagain {
  color: #0065fc !important;
}

.userSidebar {
  margin: 0 0 0 25px;
  display: inline-block;
  font-size: 10px;
  font-weight: medium;
  text-transform: uppercase;
  letter-spacing: widest;
  padding: 0.35em 0.5em;
  border-radius: 3px;
}

.sidebar-container {
  margin-bottom: 24px;
  margin-top: 16px;
  margin-left: 25px;
}

.sidebar-content {
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.sidebar-letter {
  width: 35px;
  min-width: 35px;
  height: 35px;
  min-height: 35px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  border-radius: 8px;
  color: white;
  font-size: 24px;
  font-weight: 700;
  background: linear-gradient(45deg, rgb(21, 87, 205) 0%, rgb(90, 225, 255) 100%);
  margin-right: 10px;
  /* margin-left: 4px; */
}

.sidebar-title {
  margin: 0px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
  color: #000;
  /* color: rgb(0, 152, 229); */
  /* color: #330582; */
}

.mainadBanner {
  margin-top: 15px;
  padding: 0 20px !important;
}


.customSidebarMain {
  width: 100%;
 
  /* width: 50%; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  border-radius: 8px;
  color: white;
  background: linear-gradient(45deg, rgb(21, 87, 205) 0%, rgb(90, 225, 255) 100%);
}

.customSidebarIcon {
  margin-bottom: 12px;
}

.customSidebarTitle {
  margin: 0px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
}

.customSidebarVersion {
  letter-spacing: 1px;
  opacity: 0.7;
}

.customSidebarLinkContainer {
  margin-top: 16px;
}

.customSidebarLinkText {
  color: #607489;
  font-weight: 600;
}



/* Scroll Bar */
/* Global styles for scrollbar */

/* Style for the scrollbar track (background) */
::-webkit-scrollbar-track {
  background-color: #e8e8e8;  
  border-radius: 10px;
}

/* Style for the scrollbar handle */
::-webkit-scrollbar-thumb {
  background-color: #a9a9a9; 
  border-radius: 10px;
  border: 2px solid #e8e8e8; 
}


 ::-webkit-scrollbar-thumb:hover {
  background-color: #808080; 
} 


/* Style for the scrollbar width and height */
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}


.heading-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%; /* Full width inside the parent container */
}

.table-home-head {
  margin: 0;
  padding: 0;
}

.ra-homeTable {
  border: solid 1px var(--grey);
  border-top-left-radius: 0.5rem;  /* Radius for top left corner */
    border-top-right-radius: 0.5rem; /* Radius for top right corner */
    border-bottom-left-radius: 0;    /* No radius for bottom left corner */
    border-bottom-right-radius: 0; 
  padding: 0.6rem;
}

.ra-homeTable2 {
  border-top-left-radius: 0;  /* Radius for top left corner */
  border-top-right-radius: 0; /* Radius for top right corner */
  border-bottom-left-radius: 0.5rem;    /* No radius for bottom left corner */
  border-bottom-right-radius: 0.5rem;  
}

.row-border {
  border-bottom: 1px solid #ccc;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.text-heading {
  margin: 0;
  padding: 0;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5rem;
  color: rgba(0, 0, 0, 1);
}

.text-heading-anchor {
  color: #330582;
  text-decoration: none;
  margin: 0;
  padding: 0;
}

.text-heading-anchor:hover {
  color: #0065fc;
}


.text-div {
  margin-right: 30px;
}

.mainseedhome {
  color: #000;
}
.mainseedhome:hover {
  color: #1d60b0;
  text-decoration: underline;
  cursor: pointer;
}


/* Accord */

.collapse:not(.show) {
  display: grid !important;
}

.collapse {
  margin-bottom: 1rem;
  display: grid !important;
} 


.fill-primary-200 {
  fill: #D0BCFF !important;
}

.fill-primary-600 {
  fill: #6750A4 !important;
}

.navatag {
  color: #202124 !important;
  /* width: 80px !important; */
  margin-bottom: 0.4rem !important ;
}

.ptagnavbar {
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: .5px;
}

.spantabnavbar {
  /* background: #cbf0f8; */
  border-radius: 16px;
  padding: 5px 15px;
  position: relative;
}

.spantabnarvaractive {
  background: #cbf0f8;
}

.spantabnavbar:hover {
  /* background: #3c4043; */
  border-radius: 16px;
  padding: 5px 15px;
  /* opacity: 0.04; */
 
}

.spantabnavbar:before {
  content: ''; /* Important for generating the :before element */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #3c4043;
  opacity: 0; /* Initially set to 0 */
  border-radius: 16px; /* Match the parent's border-radius */
  transition: opacity 0.2s; /* Smooth transition for the opacity */
}

.spantabnavbar:hover:before {
  opacity: 0.04; /* Increase the opacity on hover */
}

.welcome-text {
  font-weight: 500;
  font-size: 14px;
  color: rgb(151, 159, 168);
}

.credits-numbers {
  font-weight: 600;
  font-size: 18px;
  /* color: rgb(151, 159, 168); */
  color: rgb(151, 159, 168);
}

.user-name {
  font-weight: 600;
  font-size: 18px;
  width: fit-content;
  color: rgb(7, 20, 39);
}
.usermain {
  font-weight: 500 !important;
  font-size: 22px !important;
}

.detail-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 7px;
  height: 21px;
}

.detail-group {
  display: flex;
  align-items: center;
}

.label {
  font-weight: 500;
  font-size: 12px;
  color: rgb(151, 159, 168);
}

.value {
  font-weight: 600;
  font-size: 12px;
  color: rgb(7, 20, 39);
}

.btn-primary {
  width: 100%;
  /* Add more styling for the button as needed */
}

.zeroAddOn {
  color: rgb(228, 77, 95);
}

.btn-info-custommain {
  color: #fff !important;
}

.keywords-name-list:hover {
  color: #1890ff;
  cursor: pointer;
  text-decoration: underline;
   /* For positioning the icon */

}

.keywords-name-list {
  display: flex;
  align-items: center; /* Vertically center content */
  gap: 8px; /* Space between text and icon */
}

.item-text-main {
  color: rgb(7, 20, 39) !important;
}

.list-keyword-hover:hover {
  background-color: rgb(247, 248, 250);
 
}

.item-text-main:hover {
  color: #1890ff !important;
}

.helptext {
  font-family: Inter,sans-serif;
  font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #0f172a;
}

.helptitle {
  font-family: Inter,sans-serif;
  font-style: normal;
    /* font-weight: 400; */
    font-size: 23px;
    line-height: 17px;
    color: #0f172a;
}


.tab-selected {
  color: rgb(24, 144, 255);
  /* border-bottom: 2px solid rgb(24, 144, 255); */
  font-weight: 600;

}

.tab-unselected {
  font-weight: 500;
  color: rgb(151, 159, 168);
  /* border-bottom: 1px solid rgb(151, 159, 168); */
}

/* .custom-input:focus {
  border-width: 1.5px !important; 
} */

/* .btn-info-customsearch{
  background-color: #1890ff !important;
  width: 100%;
  font-size: 20px !important;
  padding: 0 !important; 
}*/

 /* background-color: #1890ff !important; */
/* .btn-info-customsearch{
 
  background-color: rgb(24, 144, 255) !important;
  width: 100%;
  font-size: 22px !important;
  padding: 0 !important;
  height: 40px !important;
  font-weight: 500;
} */

.icon-text-container {
  display: flex;
  align-items: center; /* Ensures both icon and text are aligned perfectly */
}

.btn-info-customsearch {
  background-color: rgb(24, 144, 255) !important;
  width: 100%;
  font-size: 20px !important;
  padding: 0 !important;
  height: 40px !important;
  font-weight: 500;
  display: flex; 
  align-items: center;
  justify-content: center;
}

.search-icon {
  font-size: 17px !important;
  /* display: inline-block !important;
  vertical-align: middle !important; */
  margin-right: 6px; /* Adjust spacing between icon and text */
}


.btn-info-customsearch:hover {
  background-color: rgb(30, 160, 270) !important; /* Slightly lighter or darker */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
  transform: scale(1.05); /* Slight scale up */
  color: #fff; /* Optional: Change text color if needed */
}

/* .search-icon {
  font-size: 17px !important;
  display: inline-block !important;
  vertical-align: middle !important;
  /* margin-right: 4px !important;  
} */

.tutColor {
  color: #75747f !important;
  /* color: #fff !important; */
}

.tutText {
  font-weight: 500;
}

.tutDiv {
  cursor: pointer;
  padding: 4px 6px;
}

.tutDiv:hover {
background-color: #e8e8e8;
border-radius: 5px;
}

.keyword-discovery-container {
  margin-top: 15px;
  padding: 12px;
  border-radius: 8px;
  /* background-color: #f8f9fb; */
  /* background-color: #E8EDFB; */
  /* background-color: #F8FAFA */
  /* background-color: #34c38f; */
  /* background-color: #F1F2F6; */
  /* background-color: #54c7ec; */
  /* background-color: rgba(241, 242, 246, 0.5); https://www.swifty.so/*/
  background-color: hsl(220 14.3% 95.9%);
  /* color: #fff */
  border: 1px solid hsl(220 13% 91%);
  
}

.keyword-description {
  margin: 0;
  /* font-family: "IBM Plex Sans"; */
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.25px;
  line-height: 18px;
  /* color: rgb(10, 7, 26); */
  /* color: #7C7C7A; */
  /* color: rgb(124, 124, 122); */
  /* color: hsl(220.9 39.3% 11%); */
  color: hsl(220.9 39.3% 11%);
}


/* Tags */

.tag-container {
  /* Styles for the main tag container */
  display: flex; /* Use flex display */
  flex-wrap: wrap;
}

.tag-item {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.8em;
  font-weight: 700;
  font-size: 14px;
  border-radius: 8px;
  min-width: 150px; /* Minimum width, but can grow based on content */
  height: 48px;
  transition: all 220ms;
  margin-right: 10px;
  padding: 0 10px; /* Padding for content */
  /* background-color: #efefef; */
}

.tag-label {
  color: #000c;
  font-size: 13px;
  text-transform: lowercase;
  overflow: hidden;
  white-space: nowrap; /* Prevents the label from wrapping */
}

.tag-hash {
  color: #0000004d;
  font-size: 16px;
  margin-right: 1px;
}

  .tag-add-button {
    /* Styles for the '+' button */
    /* background-color: #f5f6f7; */
    background-color: rgb(231, 227, 217);
    border-radius:  6px;
    padding: 0 10px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #a1a5b0;
     /* width: 30px !important; */
    font-size: 15px !important;
   
  }
  .tag-add-button1 {
    /* Styles for the '+' button */
    /* background-color: #f5f6f7; */
    background-color: rgb(207, 209, 201);
    border-radius:  6px;
    padding: 0 10px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* color: #a1a5b0; */
     /* width: 30px !important; */
    /* font-size: 15px !important; */
    color: #000c;
    font-weight: 700;
    font-size: 14px;
    margin-left: 6px;
   
  }
  .tag-add-button2 {
    /* Styles for the '+' button */
    /* background-color: #f5f6f7; */
    background-color: rgb(186, 192, 190);
    border-radius:  6px;
    padding: 0 10px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* color: #a1a5b0; */
     /* width: 30px !important; */
    /* font-size: 15px !important; */
    margin: 0 6px;
    color: #000c;
    font-weight: 700;
    font-size: 14px;
   
  }
  .tag-add-button3 {
    /* Styles for the '+' button */
    /* background-color: #f5f6f7; */
    background-color: rgb(220, 219, 214);
    border-radius:  6px;
    padding: 0 10px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000c;
    font-weight: 700;
    font-size: 14px;
     /* width: 30px !important; */
    /* font-size: 15px !important; */
   
  }

  .tag-add-button3:hover, .tag-add-button1:hover, .tag-add-button2:hover {
    background-color: #dae2e8; /* Slightly lighter background color on hover */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.20); /* Subtle shadow for depth */
    transform: scale(1.00); 
    cursor: pointer;
  }

/* .tag-item:hover {
  background-color: #b8c0ce;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05); 
} */

.tag-item:hover {
  background-color: #dae2e8; /* Slightly lighter background color on hover */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.10); /* Subtle shadow for depth */
  transform: scale(1.05); /* Slight scale-up effect */
}

.tag-item:hover .tag-label {
  color: #3a3f45; /* Darker text color for contrast */
}

.tag-add-button:hover {
  background-color: #e1e5eb; /* Lighter background on hover */
  color: #55607d; /* Slightly darker color for text on hover */
  transform: translateY(-2px); /* Subtle lift effect */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
}

.header-bg {
  background-color: #fafafa !important;
  padding: 8px 16px;
  border-bottom: none;
  color: #777e93 !important;
  /* font-weight: 500; */
}

.rdt_TableHeadRow {
  background-color: #fafafa !important;
  /* padding: 8px 16px; */
  /* border-bottom: none; */
  color: #777e93 !important;
}


.tag-icon {
  /* color: #6c757d;  */
  /* color: #69697e;  */
  color: #51515e; 
  cursor: pointer;
  /* font-size: 1em;  */
  margin: 0 auto;
}

.tag-icon.tagged {
  /* color: #ff5d64; a vibrant green when the icon is clicked/tagged */
  color: #9ccfda; /* a vibrant green when the icon is clicked/tagged */
}

.tagged {
  /* color: #ff5d64; */
  /* color: RGB(30, 160, 255); */
  /* color: #25c2a0; */
  color: #9ccfda;
}

.deleteed {
  cursor: pointer;
  /* color: #ff5d64; */
  color: rgb(255, 86, 48);
}
.logouticon {
  cursor: pointer;
}

.bgwhite {
  background-color: #fff;
  border: solid 1px #a39595;
}

.ra-credits > p:hover {
  background: rgb(247, 248, 250) !important;
  color: rgb(151, 159, 168) !important;
}

.ra-lang {
  display: flex;
  align-items: center; /* Vertically center the contents */
  justify-content: center; /* Horizontally center the contents */
}

.kwlength {
  color: #000;
}

/* Ant Tabs */


.custom-tabs .ant-tabs-tab {
  margin-left: 16px !important;
  font-size: 16px;
  /* font-weight: 500; */
}

.custom-tabs .ant-tabs-tab:first-child {
  margin-left: 1px !important;
}

/* 

.custom-tabs .ant-tabs-tab {
  margin-right: 12px;
  padding: 2px 16px;
  font-size: 15px;
  font-weight: 400;
  color: #455A64; 
  border-radius: 4px;
  transition: all 0.3s;

}

.custom-tabs .ant-tabs-tab:hover {
  color: #1E88E5;
  background-color: rgba(30, 136, 229, 0.1);
}

.custom-tabs .ant-tabs-tab.ant-tabs-tab-active {
  color: #1E88E5;
 
}

.custom-tabs .ant-tabs-ink-bar {
  display: none; 
}

.custom-tabs .ant-tabs-nav {
  margin: 0;
  padding: 4px 0;
  background-color: #fff;
}

.custom-tabs .ant-tabs-tab-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-tabs .tab-icon {
  margin-right: 8px;
  font-size: 18px;
}


.custom-tabs .ant-badge-count {
  background-color: #1E88E5;
  color: #fff;
} */

.no-hover-effect {
  font-weight: 500 !important;
}
.no-hover-effect:hover {
  color: inherit !important; /* Keeps the text color the same on hover */
  background-color: inherit !important; /* Keeps the background color the same on hover */
  border-color: inherit !important; /* Keeps the border color the same on hover */
}


.savebutton {
  color: #fff ;
  background-color: #1890ff !important;
  border: none !important;
}
.savebutton:hover {
  color: #fff !important;
}

.login-form-button {
  width: 100%;
  background-color: #1890ff;
  border-color: #1890ff;
  color: white;
  height: 40px;
  font-weight: bold;
}
.login-form-button:hover {
  background-color: #40a9ff;
  border-color: #40a9ff;
}
.login-form-forgot {
  float: right;
  color: #1890ff;
  cursor: pointer;
}
.login-form-forgot:hover {
  text-decoration: underline;
}

.register-form {
  color: #1890ff;
  cursor: pointer;
}

.register-form:hover {
  text-decoration: underline;
}

.newtabicon {
  display: inline ;
  /* font-size: 13px !important; */
  width: 13px !important;
  font-weight: 400 !important;
  margin-left: 2px !important;
}

/* Dragable */
.draggable-container {
  border: 1px solid #ccc;
  padding: 10px;
  background-color: #f9f9f9;
  cursor: move; /* Optional: changes the cursor on the entire draggable area */
}

.handle {
  background-color: #ddd;
  border: 1px solid #ccc;
  padding: 5px;
  margin-bottom: 5px;
  cursor: pointer; /* Cursor for the drag handle */
}

.xaxis {
  overflow-x: hidden !important;
} 

.koyZDM  {
  width: calc(100% + 25px) !important;
  overflow-x: auto !important;
  padding: 0 !important;
  margin: 0 !important;
}

.customwidthrecent {
  height: 318px !important;
}

.mozmodalFirdt {
  margin-top: 30px;
}

.mozmodal {
  margin-top: 15px ;
}



.threshold-selector {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.description-text {
  margin: 0 0 15px 0;
  color: #666;
  font-size: 0.9em;
}


.copy-icon {
  cursor: pointer;
  margin-right: 10px;
  padding: 5px;
  border: 1px solid #ccc;
  background-color: #fff;
  border-radius: 4px; /* optional for rounded corners */
  font-size: 16px; /* adjust as needed */
  display: inline-flex; /* ensures proper centering and layout of the icon */
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease; /* smooth transition for hover effect */
}

.copy-icon:hover {
  background-color: #f0f0f0; /* change to your preferred hover background color */
}

/* 
.ant-col .clastersGroundName {
  font-size: 24px !important;
  line-height: 24px !important;
  color: #4A90E2 !important;
  padding-right: 10px !important;
  margin-bottom: 15px !important;

} */


.questionIcon {
  font-size: 13px;
}

.mainQuoraButton {
  background-color: #1677ff !important;
  width: 100%;
}
.mainQuoraButtonComment {
  background-color: #1677ff !important;
  color: #fff;
}

.react-resizable {
  position: relative;
  background-clip: padding-box;
}

 .react-resizable-handle {
  position: absolute;
  width: 10px;
  height: 100%;
  bottom: 0;
  right: -5px;
  cursor: col-resize;
  z-index: 1;
}



/* TanStack Table */

/* customTableStyles.css   width: fit-content;  bad me add ki*/

.custom-ant-table {
  width: 100%;
  border-collapse: collapse;
  background-color: #fff;

  width: fit-content; 
}

.custom-ant-table thead .ant-table-cell {
  background: #fafafa;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  text-align: left;
  border-bottom: 1px solid #f0f0f0;
  /* padding: 1px; */
}

.custom-ant-table tbody .ant-table-row {
  border-bottom: 1px solid #f0f0f0;
}

.custom-ant-table tbody .ant-table-cell {
  text-align: left;
  padding: 2px;
  color: rgba(0, 0, 0, 0.65);
}

.custom-ant-table tbody .ant-table-row:hover {
  background-color: #fafafa;
}

.custom-ant-table .not-analyzed {
  padding: 16px;
}

.selected {
  background-color: #e6f7ff; /* Light blue background for selected rows */
}

/* Custom padding for header checkbox */
.custom-ant-table thead .ant-table-cell .ant-checkbox-wrapper {
  padding-left: 4px;
}

/* Custom padding for body checkbox */
.custom-ant-table tbody .ant-table-cell .ant-checkbox-wrapper {
  padding: 0 2px;
}



/* Example Table */

/* 
table,
.divTable {
  border: 1px solid lightgray;
  width: fit-content;
}

.tr {
  display: flex;
}

tr,
.tr {
  width: fit-content;
  height: 30px;
}

th,
.th,
td,
.td {
  box-shadow: inset 0 0 0 1px lightgray;
  padding: 0.25rem;
  white-space: wrap;
  overflow: hidden; 
  text-overflow: ellipsis;
}

th,
.th {
  padding: 2px 4px;
  position: relative;
  font-weight: bold;
  text-align: center;
  height: 30px;
}

td,
.td {
  height: 30px;

}

td,.td:hover {
  overflow: unset;
  text-overflow: unset;
}

.resizer {
  position: absolute;
  top: 0;
  height: 100%;
  right: 0;
  width: 5px;
  background: rgba(0, 0, 0, 0.5);
  cursor: col-resize;
  user-select: none;
  touch-action: none;
}

.resizer.isResizing {
  background: blue;
  opacity: 1;
}

@media (hover: hover) {
  .resizer {
    opacity: 0;
  }

  *:hover > .resizer {
    opacity: 1;
  }
}
 */

.noraperr > * {
  white-space: normal !important;
}

.css-zyf4e1-getRowContainerStyle-Row.row-select-selected {
  font-weight: normal !important;
}

.toggle-container {
  text-align: right;
  padding: 10px;
}
.toggle-button {
  padding: 8px 15px;
  background-color: white;
  color: #007bff;
  border: 1px solid #ddd;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.toggle-button i {
  margin-right: 5px;
}

.toggle-button:hover {
  background-color: #f8f8f8;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.toggle-button:focus {
  outline: none;
  /* border-color: #007bff; */
}

/* Ensuring the chart fits within its container */
.monthly-volume-chart {
  box-sizing: border-box; /* Ensures padding and border are included in the total width and height */
  max-width: 100%; /* Ensures it doesn't exceed the width of its parent */
  overflow: hidden;
}

.homapaherecent {
  opacity: 0.5; /* Adjust opacity as needed */
  mix-blend-mode: multiply;
  height: 20px;
  width: 20px;
}

/* CustomStyles.css */
.equal-height-col {
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* This will push the button to the bottom */
  height: 100%; /* This ensures all columns have the same height */
}

.bottom-aligned-button {
  margin-top: auto; /* This will push the button to the bottom of the column */
}

/* 
.ranking-not-ranked {
  color: grey;
}

.ranking-symbol {
  margin-right: 5px;
  font-weight: bold;
}

.ranking-↑ {
  color: green;
}

.ranking-↓ {
  color: red;
} */

.chart-container {
  display: flex;
  align-items: flex-end;
  height: 80px;
  width: 100%;
  padding: 2px;
}

.chart-column {
  width: 12%; /* Adjusted for 7 columns with spacing */
  margin: 0 1%;
  background-color: #7ebdc2; /* Consistent color for a clean look */
  border-radius: 5px; /* Rounded corners for columns */
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease;
  cursor: pointer;
  position: relative;
}

.chart-column:hover {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
}

.chart-column:hover::after {
  content: attr(data-rank); /* Use a custom attribute for the rank */
  position: absolute;
  bottom: 100%; /* Position above the column */
  left: 50%;
  transform: translateX(-50%);
  background-color: #333; /* Tooltip background */
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 14px;
  white-space: nowrap;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
}



/* Add this CSS to your stylesheet */
.left-text {
  text-align: left;
}

.right-text {
  text-align: right;
}


.custom-table .ant-table-tbody > tr > td {
  height: auto !important;
}

.ant-table-row .ant-radio-wrapper .ant-radio-inner {
  border-color: #1677ff; /* Use your primary color here */
  border-width: 2px;
}


.containerGuide {
    max-width: 1100px;
    margin: 10px 0 0 0;
    background-color: #fff;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

/* .title {
    color: #0079d3;
    text-align: center;
} */



.guide-list a {
    color: #0079d3;
    text-decoration: none;
}

.guide-list a:hover {
    color: #0056a3;
}

.guide-list code {
    font-family: monospace;
    background-color: #eee;
    padding: 2px 4px;
    border-radius: 5px;
}

.guide-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Creates two columns */
  gap: 10px; /* Adjusts the space between grid items */
}

.guide-list div {
  background-color: #f0f0f0; /* Background color for each guide point */
  padding: 10px; /* Padding inside each guide point */
  border-radius: 5px; /* Rounded corners for each guide point */
}
.guide-list li {
  margin-bottom: 10px;
  padding: 10px;
  background-color: #f0f0f0;
  border-radius: 5px;
}

.mainfontsize {
  font-size: 20px !important;
}

.presets {
  display: flex;
  align-items: center;
}

.presets h6 {
  margin: 0; 
  color: #86898c;
  display: flex; 
  align-self: center;
}

.presetsBadge {
  height: 20px;
  line-height: 20px;
  border-radius: 2px;
  color: #fff;
  padding: 0 4px;
  display: inline-block;
  font-size: 13px;
  background: linear-gradient(#5BC891, #31C993) !important;
  cursor: pointer;
}
.presetsBadgeCustom {
  height: 20px;
  line-height: 20px;
  border-radius: 2px;
  color: #fff;
  padding: 0 4px;
  display: inline-block;
  font-size: 13px;
  background-color: #fb8c1e;
  cursor: pointer;
  /* width: 100%; */
}

.presetsContent {
  display: flex;
  flex-wrap: wrap;
  align-items: center; /* Adjust this as needed */
  gap: 5px; /* This replaces your marginLeft styles */
}

.presetsBadgeSave {
  height: 20px;
  line-height: 20px;
  border-radius: 2px;
  color: #86898c;
  padding: 0 4px;
  display: inline-block;
  font-size: 13px;
  background: #f0f2f5;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
/* .presetsBadgeAdd {
  height: 20px;
  line-height: 20px;
  border-radius: 2px;
  color: #97a4af;
  padding: 0 4px;
  display: inline-block;
  font-size: 13px;
  color: #9fa3a8;
  border-bottom: 1px dashed #97a4af;
} */



.presetsBadgeAdd {
  height: 20px;
  line-height: 20px;
  border-radius: 2px;
  color: #97a4af;
  padding: 0 4px;
  display: inline-block;
  font-size: 13px;
  
  /* Additional styles for input element, if needed: */
  border: none; /* Remove default input border */
  outline: none; /* Remove default input outline */
  background-color: transparent; /* Make background transparent for hover effects */
  cursor: pointer; /* Indicate clickable behavior #00A96C;  */
  border-bottom: 1px dashed #97a4af;
}

.colorClass {
  background-color: #e27204 !important;
}

  .colorNamea {
    color: #7C7C7A !important;
  }

.marginb20 {
margin-bottom: 7rem !important;
}

.newmargin {
  margin-top: -2px;
}


.newmargin1 {
  margin-top: -6px;
}


/* Filter Button */

.ant-table-filter-dropdown-btns .ant-btn-primary {
  background-color: #1677ff !important; /* Desired background color */
  border-color: #1677ff !important;     /* Desired border color */
  color: #fff !important;               /* Text color */
}

.chatgpt-button {
  color: black !important; /* Override any existing styles */
}

.chatgpt-button .ant-btn-icon {
  color: black !important; /* Ensure the icon itself is black */
}